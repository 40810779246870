.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.frameImg{  
  max-width: 100%;   
}
.tituloVideo{
  color: rgb(17, 126, 222);
  size: 16px;
}
.item{
  border-radius: 8px;
  padding: 5px;
  border: 2px;
  border-style: dotted;
  border-color: rgb(223, 223, 223);
  padding-bottom:20px ;
}
.btLeft{
  float: left;
  width: 50px;
  cursor: pointer;
 /* position:absolute;
  z-index: 100;*/
}
.btRight{
  float: right;
  width: 50px;
  cursor: pointer;
 /* position:absolute;
 z-index: 100;*/
}
.fondoFramesTube{
  background-color:#ffffff;


}
.panelTube{
  background-color:#282c34;
  border-radius: 8px;
  border: 1px;
  border-color: gray;
  position:fixed;
  z-index: 2000;
  width: 90%;
  top: 10px;
  left: 10px;
  right: 10px;
  float: inline-start;
}
.btVerVideo{
  color: aliceblue;
  font-size: 14;
  background-color: royalblue;
  padding:3px;
  width: 30%;
  cursor: pointer;
  border-radius: 5px;
  border-style: dotted;
}
.btCerrar{
  color: aliceblue;
  font-size: 14;
  background-color: indigo;
  padding:3px;
  width: 30%;
  cursor: pointer;
  border-radius: 5px;
  border-style: dotted;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
